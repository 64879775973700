import { createStore } from 'vuex';
import axios from 'axios'
import VueCookies from 'vue-cookies';
import orderStore from '@/store/modules/orderStore.js'
import senderStore from '@/store/modules/senderStore.js'

export default new createStore({
    state: {
        user:{
            loggedIn: false,
            data: null
        }
    },
    mutations: {
        setLoggedIn(state, value){
            state.user.loggedIn = value;
        },

        setUser(state, data){
            state.user.data = data;
            VueCookies.set('tipsbeeweb_accessToken', data.accessToken);
            VueCookies.set('tipsbeeweb_refreshToken', data.refreshToken);
            VueCookies.set('tipsbeeweb_useruid', data.useruid);
            VueCookies.set('tipsbeeweb_email', data.email);
            VueCookies.set('tipsbeeweb_phone', data.phone);
            VueCookies.set('tipsbeeweb_username', data.username);
            VueCookies.set('tipsbeeweb_usertype', data.usertype);
            VueCookies.set('tipsbeeweb_userid', data.userid);
        },

        logoutUser(state){
            state.user.loggedIn = false;
            state.user.data = null;
            VueCookies.remove('tipsbeeweb_accessToken');
            VueCookies.remove('tipsbeeweb_refreshToken');
            VueCookies.remove('tipsbeeweb_useruid');
            VueCookies.remove('tipsbeeweb_email');
            VueCookies.remove('tipsbeeweb_phone');
            VueCookies.remove('tipsbeeweb_username');
            VueCookies.remove('tipsbeeweb_usertype');
            VueCookies.remove('tipsbeeweb_userid');
        },
    },
    getters: {
        user(state){
            return state.user;
        },
    },
    actions: {},
    modules: {
        orderStore: orderStore,
        senderStore: senderStore,
    },
});
